import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const List = ({ isVisible }) => {
	const data = useStaticQuery(graphql`
		query SourateQuery {
			directus {
				quran_surah(limit: 114) {
					id
					ayah
					latin
					slug
					arabic
				}
			}
		}
	`);

	if (isVisible) {
		return (
			<div>
				{/* <h1>LIST</h1> */}

				{data.directus.quran_surah.map((surah) => (
					// <li key={surah.id}>
					// 	<Link to={"/sourates/" + surah.slug}>{surah.latin}</Link>
					// </li>
					<Link to={"/sourates/" + surah.slug} key={surah.id}>
						<div className="flex justify-between items-center border-b border-slate-700 mb-8 pb-6">
							<div className="flex w-9 h-9 bg-number-pattern bg-no-repeat bg-top mr-4 justify-center items-center text-xs">
								{surah.id}
							</div>
							<div className="flex-initial w-64">
								{surah.latin}{" "}
								<span className="text-slate-700 dark:text-slate-500 text-xs"> &#8226; {surah.ayah} ayah</span>
							</div>
							<div className="flex-auto text-right text-xl">{surah.arabic}</div>
						</div>
					</Link>
				))}
			</div>
		);
	}
	return null;
};

export default List;
