import * as React from "react";
import Layout from "../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import Hero from "../components/hero";
import List from "../components/sourates/list";
import Grid from "../components/sourates/grid";

const IndexPage = () => {
	const [isVisible, setIsVisible] = React.useState(false);
	const activeIconList = isVisible
		? "h-5 w-5 fill-teal-500 hover:fill-cyan-500"
		: "h-5 w-5 fill-teal-700 hover:fill-cyan-500";
	const activeIconGrid = isVisible
		? "h-5 w-5 fill-teal-700 hover:fill-cyan-500"
		: "h-5 w-5 fill-teal-500 hover:fill-cyan-500";
	function toggleVisibility(value) {
		setIsVisible((prevIsVisibleValue) => {
			let newState = value == "grid" ? false : true;
			return newState;
		});
	}

	return (
		<Layout pageTitle="Home Page">
			<div className="p-8">
				<Hero />
				<div className="justify-end hidden md:flex md:mb-8">
					<button aria-label="Vue en liste" onClick={() => toggleVisibility("list")}>
						<svg xmlns="http://www.w3.org/2000/svg" className={activeIconList} viewBox="0 0 20 20" fill="currentcolor">
							<path
								fillRule="evenodd"
								d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
					<button aria-label="Vue en tableau" onClick={() => toggleVisibility("grid")}>
						<svg xmlns="http://www.w3.org/2000/svg" className={activeIconGrid} viewBox="0 0 20 20" fill="currentColor">
							<path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
						</svg>
					</button>
				</div>
				<List isVisible={isVisible} />
				<Grid isVisible={!isVisible} />
			</div>
		</Layout>
	);
};

export default IndexPage;
