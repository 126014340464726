import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Box from "./box";

const Grid = ({ isVisible }) => {
	const data = useStaticQuery(graphql`
		query {
			directus {
				quran_surah(limit: 114) {
					id
					ayah
					latin
					slug
					arabic
				}
			}
		}
	`);
	if (isVisible) {
		return (
			<div>
				{/* <h1>GRID</h1> */}
				<div className="md:grid flex flex-col md:grid-cols-3 md:gap-3">
					{data.directus.quran_surah.map((surah) => (
						<Link to={"/sourates/" + surah.slug} key={surah.id}>
							<Box surah={surah} />
						</Link>
					))}
				</div>
				<ul></ul>
			</div>
		);
	}
	return null;
};

export default Grid;
