import React from "react";

export default function hero() {
	return (
		<div className="flex justify-center items-center flex-col font-bold mt-20 mb-48">
			<div className="w-5/6 flex justify-center items-center">
				<p className="text-black dark:text-white">
					Coran Abdullah Yusuf Ali traduit en français par <span className="text-teal-500">LaityModji Ndiaye</span>
				</p>
			</div>
			<div className="w-5/6 flex justify-center items-center pt-12 text-center text-2xl md:text-4xl mt-12">
				<p className="text-black dark:text-white">
					Traduction de l’anglais vers le français, de la totalité du livre,{" "}
					<span className="text-teal-500">"THE HOLY QURAN, TEXT, TRANSLATION AND COMMENTARY"</span> d’Abdullah Yusuf
					Ali.
				</p>
			</div>
		</div>
	);
}
