import React from "react";

function Box(props) {
	// console.log(props);
	return (
		<div>
			<div className="flex justify-between items-center border border-slate-700 mb-8 pb-6 p-4">
				<div className="flex w-9 h-9 bg-number-pattern bg-no-repeat bg-top mr-4 justify-center items-center text-xs">
					{props.surah.id}
				</div>
				<div className="flex-initial w-32">
					{props.surah.latin}{" "}
					<span className="text-slate-700 dark:text-slate-500 text-xs block"> &#8226; {props.surah.ayah} ayah</span>
				</div>
				<div className="flex-auto text-right text-xl">{props.surah.arabic}</div>
			</div>
		</div>
	);
}

export default Box;
